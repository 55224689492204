import React, { useEffect, useState } from "react"
import Layout from '../components/global/layout.js'

import projectsStyles from '../style/projects.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import ProjectCard from "../components/projects/projects.card.js"
import Image from "../components/global/image.js"

import xcImg from "../img/projects/Mockup_XC_lg.jpg"
import imImg from "../img/projects/Teaser_IM.jpg"
import emImg from "../img/projects/Teaser_EM.jpg"

import ClientList from "../components/projects/projects.clients.js"
import LazySlide from "../components/global/lazy.slide.js"



const allProjects = [
    {
        title: "Exhibitor Catalogue",
        url: "../project_exhibitorcatalogue",
        client: "TradeX Services",
        year: "",
        category: 'CMS',
        bg: {
            src: xcImg,
            alt: "Projects.exhibitorcatalogue.img1.alt"
        },
    },
    {
        title: "Digital Signage",
        url: "../project_digitalsignage",
        client: "Infinity Media",
        year: "",
        category: 'Digital Signage',
        bg: {
            src: imImg,
            alt: "Projects.digitalsignage.img1.alt"
        },
    },
    {
        title: "EM Live-Ticker",
        url: "../project_emticker",
        client: "Infinity Media",
        year: "",
        category: 'Digital Signage',
        bg: {
            src: emImg,
            alt: "Projects.emticker.img1.alt"
        },
    },
]

const allFilters = [...new Set(allProjects.map(project => project.category))]

const ProjectsPage = () => {

    const [filters, setFilters] = useState([])
    const [projects, setProjects] = useState([])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        setFilters(urlParams.getAll('filter'))
    }, [])

    useEffect(() => {
        if (filters.length) setProjects(allProjects.filter(project => filters.includes(project.category)))
        else setProjects(allProjects)
    }, [filters])


    function getBtnClass(filter) {
        return filters.includes(filter) ? projectsStyles.neurobutton_active : projectsStyles.neurobutton
    }


    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Projects </title>
                <meta name="description" content="Full-stack Software Design und Development - Ein Einblick in unsere Lieblingsprojekte"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Projects.h1" />}
                title={<FormattedMessage id="Projects.title" />}
                subtitle="Projects.subtitle"
            />

            <ContentSection>
                <div className={projectsStyles.projectsContent}>
                    <div>
                        <div className={projectsStyles.filterContainer}>
                            <h2><FormattedMessage id="Projects.showcases" /></h2>
                            <div>
                                {
                                    allFilters.map(filter => {
                                        return (
                                            <button
                                                onClick={() => {
                                                    if (filters.includes(filter)) setFilters(filters.filter(f => f !== filter))
                                                    else setFilters(filters => [...filters, filter])
                                                }
                                                }
                                                className={getBtnClass(filter)}>{filter}
                                            </button>
                                        )
                                    })
                                }
                                <button
                                    onClick={() => { setFilters([]) }}
                                    className={filters.length ? projectsStyles.neurobutton : projectsStyles.neurobutton_active}>
                                    <FormattedMessage id="Projects.all" />  </button>
                            </div>
                        </div>
                        {
                            projects.map((project, index) => {
                                return (
                                    <LazySlide duration={`${1 + index}s`} key={`${index}-${project}`}>
                                        <ProjectCard
                                            url={project.url}
                                            bg={<Image src={project.bg.src} alt={project.bg.alt} />}
                                            title={project.title}
                                            client={project.client}
                                            year={project.year} />
                                    </LazySlide>
                                )
                            })
                        }

                    </div>

                    <h2><FormattedMessage id="Projects.clients" /></h2>
                    <LazySlide duration={"1.4s"}>
                        <ClientList />
                    </LazySlide>


                </div>
            </ContentSection>
        </Layout>


    )
}

export default ProjectsPage
